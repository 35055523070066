<template>
  <standard-page title="Ongoing Processes" :definition="$DEFINITIONS.provenance.ongoingProcess">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>Ongoing Processes</span>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :rename="updateProcess" :row-click="view" :server-side="true" @ready="onReady" list-key="processes" table-id="process_table" total-key="total">
      </data-table>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OngoingProcesss",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      api: `${this.$provenance.defaults.baseURL}/provenance/registries/processes_in_progress`,
      columns: [
        { type: 'name' },
        { type: 'provenanceProcessStatus' },
        { type: 'created' },
        { type: 'extrinsic' }, {
          type: 'action',
          defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                            ${this.$StandardButtons.renameButton('Rename Process')}
                            <span class="text-dark qr mg-r-10" style="cursor: pointer" title="View QR">
                                <i class="fa fa-qrcode fa-lg"></i>
                            </span>
                        `
        }
      ],
      table: null,
      did: null,
    };
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

        $(async function () {
            $('#process_table tbody').on('click', '.qr', function () {
                let process = table.row($(this).parents('tr')).data();
                self.$router.push({name: 'process-qr-print',  params: { registryid: process.registry, processdefinitionid: process.process_definition, processid: process.process }})
            });
        });
    },
    view(process) {
      this.$router.push({ name: 'ongoing-process-steps', params: { registryid: process.registry, processdefinitionid: process.process_definition, processid: process.process } });
    },
    async updateProcess(process, name) {
      try {
        await this.$provenance.patch(`provenance/registries/${process.registry}/definitions/${process.process_definition}/processes/${process.process}`, { name });
      } catch (e) {
        this.$toastr.e("Process updating failed", 'Error');
      }
    }
  }
}
</script>

<style scoped>
</style>