import { render, staticRenderFns } from "./OngoingProcesses.vue?vue&type=template&id=69b9f51e&scoped=true&"
import script from "./OngoingProcesses.vue?vue&type=script&lang=js&"
export * from "./OngoingProcesses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69b9f51e",
  null
  
)

export default component.exports